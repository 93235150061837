import React, { useContext, useState, useEffect } from "react";
import {
  PlusCircleIcon,
  MinusCircleIcon,
  TrashIcon,
} from "../../components/icons";
import { CartContext } from "../../contexts/CartContext";
import Modal from "react-modal";
import "../../assets/app.css";

const ProductModifier = (props) => {
   
  // function that takes boolean as param to conditionally display popup
  const { addProduct, cartItems, increase, decrease, removeProduct } =
    useContext(CartContext);
  const { setPopUp } = props;
  const product = props.product;

  function toggleModal() {
    setPopUp(false);
  }

  const IncrementItem = (data) => {
    if (isInCartQty(data) == 0) {
      addProduct(product);
    } else {
      increase(product);
    }
  };
  const DecreaseItem = (data) => {
    //function DecreaseItem(data) {

    decrease(data);

    data.quantity = isInCartQty(data);
    if (data.quantity == 0) {
      removeProduct(data);
    }
  };
  const isInCart = (product) => {
    return !!cartItems.find((item) => item.id === product.id);
  };
  const isInCartQty = (product) => {
    const cartData = cartItems.find((item) => item.id === product.id);
    if (cartData) {
      if (cartData.quantity == 0) {
        removeProduct(product);
      }
      return cartData.quantity;
    } else {
      return 0;
    }
  };

  return (
    <div>
      <Modal
        isOpen={setPopUp}
        onRequestClose={toggleModal} 
        contentLabel="My dialog"
        className="modifierPopup"
        overlayClassName="myoverlay"
      >
        <div>
          {/* <h1>Select Item</h1> */}
          <div className="col-xs-12 col-md-12 card-container" style={{textAlign:'center'}}>
           
              <img
                src="img/noimage.png"
                alt="Menu"
                
              />
              <br />
           
              <div
                  className="col-xs-8 col-md-8 card-container" style={{width:'60%',float:'left',textAlign:'left'}}>
              <span
                className="card-title"
                style={{ fontSize: "14px", fontWeight: "600" }}
              >
                 {product.menutype === 1 ? (
                                <img
                                    className="menubtn-img"
                                    src="img/veg.20069ac1.svg"
                                    alt="Menu" />) 
                                :  null} 
                                {product.menutype  === 2  ? (
                                <img
                                    className="menubtn-img"
                                    src="img/nonveg.71128d53.svg"
                                    alt="Menu" />) 
                                :  null} 
                                {product.menutype  === 3  ? (
                                <img
                                    className="menubtn-img"
                                    src="img/egg_icon.png"
                                    alt="Menu" />) 
                                :  null} 
                                &nbsp;{" "}
                
                &nbsp; {product.name}
              </span>
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
             
              <span className="card-price">₹{product.price}</span>
              {/* <span className="card-price">{formatNumber(product.price)}</span> */}
              </div>
        
              {isInCart(product) && (
                <div
                  className="col-xs-4 col-md-4 card-container"
                  style={{
                   
                    float: "right",
                    padding: "0px",
                    boxShadow: "none",
                    border: "0px",
                    margin: "0px",
                  }}
                >
                  <div
                    className="col-xs-4 col-md-4"
                    style={{ padding: "0px", margin: "0px", float: "left" }}
                  >
                    {isInCartQty(product) > 1 && (
                      <button className="Projectbackground-colorColor" 
                        onClick={(e) => DecreaseItem(product)}
                        style={{
                          borderTopRightRadius: "0px",
                          borderBottomRightRadius: "0px",
                          padding: "5px 10px 7px 10px",
                        }}
                      >
                        <MinusCircleIcon width={"20px"} />
                      </button>
                    )}

                    {isInCartQty(product) === 1 && (
                      <button
                        onClick={(e) => DecreaseItem(product)}
                        className="btn btn-danger btn-sm mb-1"
                      >
                        <TrashIcon width={"20px"} />
                      </button>
                    )}
                  </div>
                  <div
                    className="col-xs-4 col-md-4"
                    style={{
                      padding: "0px",
                      height: "33px",
                      border: "1px solid rgb(233 230 230)",
                      textAlign: "center",
                      float: "left",
                    }}
                  >
                    <h2
                      style={{
                        margin: "0px",
                        fontSize: "16px",
                        padding: "7px",
                        textAlign: "center",
                      }}
                    >
                      {isInCartQty(product)}
                      {/* {count} */}
                    </h2>
                  </div>

                  <div
                    className="col-xs-4 col-md-4"
                    style={{
                      padding: "0px",
                      margin: "0px",
                      borderRadius: "0px",
                      float: "left",
                    }}
                  >
                    <button
                      id={product.id + "e"}
                      onClick={(e) => IncrementItem(product)}
                      style={{
                        borderTopLeftRadius: "0px",
                        borderBottomLeftRadius: "0px",
                        padding: "5px 10px 7px 10px",
                      }}
                    >
                      <PlusCircleIcon width={"20px"} />
                    </button>
                  </div>
                </div>
              )}
              {!isInCart(product) && (
                <div
                  className="col-xs-4 col-md-4"
                  style={{
                    float: "right",
                    width:'30%',
                    borderRadius: "0px",
                  }}
                >
                  <button
                    onClick={(e) => IncrementItem(product)}
                    //onClick={() => addProduct(product)}
                    style={{
                      color: "#4caf50",
                      border: "1px solid #3faa43",
                      background: "white",
                      fontWeight: "bold",
                      height:" 40px"
                    }}
                  >
                    &nbsp; &nbsp; &nbsp; ADD &nbsp; &nbsp; &nbsp;
                  </button>
                </div>
              )}
             <div className="col-xs-12 col-md-12 card-container" style={{float:'left',textAlign:'left',paddingLeft:'28px',paddingBottom:'10px',fontSize:'14px',paddingTop:'10px'}}>
            <span style={{color:'black',fontSize:'15px',fontWeight:'bold'}}>
              Description : </span> 
              {props.product.recipedes}
              {/* Reminiscent of fresh soil. Often used to describe red wines, root vegetables, and mushrooms. */}
</div>
            {/* <Quantity className="Hide"   /> */}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ProductModifier;
