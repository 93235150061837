import React, { useContext, useState,createContext, useReducer } from "react";

import Layout from "../../components/Layout";
import GlobalSpinnerContextProvider from '../../contexts/GlobalSpinnerContext';
import GlobalSpinner from '../../components/GlobalSpinner/GlobalSpinner'
import ProductsContextProvider from '../../contexts/ProductsContext';
import CartContextProvider from '../../contexts/CartContext';
import CategoryContextProvider from '../../contexts/CategoryContext';
import ProductsGrid from "./ProductsGrid";
import { CartContext } from "../../contexts/CartContext"
import { CartReducer, sumItems } from "../../contexts/CartReducer";


import axios from "axios";



export const CartContext1 = createContext()
const Store = () => {

const closeNav = () => {   
    document.getElementById("mySidenav").style.width = "0";
  };
  return (
    <ProductsContextProvider>
      <CartContextProvider >
      <CategoryContextProvider>
    <Layout
      title="Store"
      description="This is the Store page"
      style={{ alignSelf: "center" }}
    >
      <div
        className="col-lg-12 col-md-12 col-xs-12"
        style={{ padding: "10px",background:'white' }}
      >
      
      <div id="mySidenav" className="sidenav">
          <a
            href="javascript:void(0)"
            className="closebtn"
            onClick={(e) => closeNav()}
          >
            &times;
          </a>
          </div>

          <GlobalSpinnerContextProvider>
          <ProductsContextProvider>
            <GlobalSpinner/>
            <ProductsGrid />
          </ProductsContextProvider>
        </GlobalSpinnerContextProvider>


       
        
      </div>      
    </Layout>
     </CategoryContextProvider>
     </CartContextProvider>
     </ProductsContextProvider>
  );
};

export default Store;
