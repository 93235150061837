import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { CartContext } from '../../contexts/CartContext';
import { formatNumber } from '../../helpers/utils';
import { PlusCircleIcon, MinusCircleIcon, TrashIcon } from '../../components/icons'
import ProductModifier from './ProductModifier';
import styles from './ProductsGrid.module.scss';
import "../../assets/checkbox.css";
import Customizable from "./Customizable";


let TotalItemCount = 0;
let TotalItemPrice = 0;
let GroupList = "";
let categoryHeading = "";


const ProductItem = ({ product, categoryName }) => {

    const { addProduct, cartItems, increase, decrease, removeProduct } = useContext(CartContext);
    // const [count = product.quantity, setCount] = useState(0); // initialize state
    //   const [status = 0, setstatus] = useState(0); // initialize status


    useEffect(() => {
        GroupList = "";
    });
    //{ setCategory(categoryName) };


    // controls if popup displays
    const [popUp, setPopUp] = useState(false)
    const [CustomizepopUp, setCustomizePopUp] = useState(false)
    // adds class to darken background color
    const duringPopUp = popUp ? " during-popup" : ""


    
    const IncrementItem = (data) => {
         
        //  setPopUp(true);
      //  if(data.customize == true)
        if(data.id == "223")
        {
            setCustomizePopUp(true);
            return;
        }
        if (isInCartQty(data) == 0) {
            addProduct(product);
        }
        else {
            increase(product)
        }


    };
    const DecreaseItem = (data) => {
        //function DecreaseItem(data) {

        decrease(data)

        data.quantity = isInCartQty(data);
        if (data.quantity == 0) { removeProduct(data); }
        TotalItemCount = cartItems.length;

    };
    const isInCart = product => {
        return !!cartItems.find(item => item.id === product.id);
    }
    const isInCartQty = product => {

        const cartData = cartItems.find(item => item.id === product.id);
        if (cartData) {
            if (cartData.quantity == 0) { removeProduct(product); }
            return cartData.quantity;
        }
        else { return 0; }

    }
    if (GroupList != categoryName) {
        GroupList = categoryName;
        categoryHeading = categoryName;

    }
    else {
        categoryHeading = "";
    }



    return (
        <>
            <div style={{ background: 'white', borderBottom: '1px solid rgb(245 241 241)' }}>
                {/* {
                categoryHeading.length > 0 ?
                    (<div className={styles.scrollmargintop} style={{
                        textAlign: 'left',
                        color: "rgb(255 255 255)",
                        background: "rgb(103 194 107)",
                        fontWeight: "bold",
                        textAlign: "left",
                        padding: '5px',
                        marginBottom: '5px',
                    }} id={categoryHeading}>
                        {categoryHeading}
                    </div>)
                    :
                    (<div></div>)
            } */}

                <div className="col-xs-12 col-md-12 card-container">
                    <div
                        className="col-xs-8 col-md-8 card-container"

                        style={{ width: "60%", float: "left" }}
                    >
                        <span
                            className="card-title"
                            style={{ width: "15%", fontSize: "14px", fontWeight: "600" }}
                            onClick={() => setPopUp(true)}     >

                            {product.menutype === 1 ? (
                                <img
                                    className="menubtn-img"
                                    src="img/veg.20069ac1.svg"
                                    alt="Menu" />) 
                                :  null} 
                                {product.menutype  === 2  ? (
                                <img
                                    className="menubtn-img"
                                    src="img/nonveg.71128d53.svg"
                                    alt="Menu" />) 
                                :  null} 
                                {product.menutype  === 3  ? (
                                <img
                                    className="menubtn-img"
                                    src="img/egg_icon.png"
                                    alt="Menu" />) 
                                :  null} 
                                &nbsp;
                              
                            {product.name}
                        </span>
                        <br />
                        &nbsp; &nbsp;&nbsp; <span className="card-price">₹{product.price}</span>
                        {/* <span className="card-price">{formatNumber(product.price)}</span> */}
                    </div>
                    <div>
                        {
                            isInCart(product) &&
                            <div
                                className="col-xs-4 col-md-4 card-container"
                                style={{
                                    float: "right",
                                    padding: "0px",
                                    boxShadow: "none",
                                    border: "0px",
                                    margin: "0px",
                                }}
                            >
                                <div
                                    className="col-xs-4 col-md-4"
                                    style={{ padding: "0px", margin: "0px", float: "left" }}
                                >


                                    {
                                        isInCartQty(product) > 1 &&
                                        <button className="Projectbackground-colorColor"
                                            onClick={(e) => DecreaseItem(product)}
                                            style={{ borderTopRightRadius: '0px', borderBottomRightRadius: '0px', padding: '5px 10px 7px 10px', width: '100%' }}>
                                            <MinusCircleIcon width={"20px"} />
                                        </button>
                                    }

                                    {
                                        isInCartQty(product) === 1 &&
                                        <button className="Projectbackground-colorColor btn btn-danger btn-sm mb-1"
                                            onClick={(e) => DecreaseItem(product)}
                                            style={{ width: '100%' }}>
                                            <TrashIcon width={"20px"} />
                                        </button>
                                    }
                                </div>
                                <div
                                    className="col-xs-4 col-md-4"
                                    style={{
                                        padding: "0px",
                                        height: "33px",
                                        border: "1px solid rgb(233 230 230)",
                                        textAlign: "center",
                                        float: "left"
                                    }}
                                >
                                    <h2
                                        style={{
                                            margin: "0px",
                                            fontSize: "16px",
                                            padding: "7px",
                                            textAlign: "center",
                                        }}
                                    >
                                        {isInCartQty(product)}
                                        {/* {count} */}
                                    </h2>
                                </div>

                                <div
                                    className="col-xs-4 col-md-4"
                                    style={{ padding: "0px", margin: "0px", borderRadius: "0px", float: "left" }}
                                >
                                    <button
                                        id={product.id + "e"}
                                        onClick={(e) => IncrementItem(product)}
                                        // onClick={()=>setPopUp(true)}
                                        style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px', padding: '5px 10px 7px 10px', width: '100%' }}>
                                        <PlusCircleIcon width={"20px"} />
                                    </button>
                                </div>



                            </div>
                        }
                        {
                            !isInCart(product) &&
                            <div
                                className="col-xs-5 col-md-3"
                                style={{
                                    marginTop: "5px",
                                    float: "right",
                                    padding: "0px",
                                    marginTop: "4px",
                                    borderRadius: "0px",
                                    width: "25%",
                                    textAlign:'center',
                                    

                                }}
                            >
                                <button
                                    onClick={(e) => IncrementItem(product)}
                                    //onClick={() => addProduct(product)}
                                    style={{
                                        padding: '5px',
                                        color: "rgb(107 109 107)",
                                        border: "1px solid rgb(224 224 224)",
                                        background: "white",
                                        fontWeight: "bold",
                                        width: "100%",
                                    }}
                                >
                                    &nbsp;   &nbsp; &nbsp;  ADD  &nbsp;  &nbsp;  &nbsp;
                                </button>
                                {product.customize === true ? (<span style={{color:'#2e8831',fontSize:'14px'}}>Customize</span>):null}

                            </div>
                        }

                    </div>
                  
                    {/* <Quantity className="Hide"   /> */}
                </div>
                {/* <div class="form-popup"  style={{display:'block',color:'white',position:'fixed',bottom:'0',zIndex:'9',boxShadow:'0 0 12px 0 rgb(0 0 0 / 50%)',borderRadius:'16px',marginLeft:'160px',marginBottom:'60px'}}><img class="menubtn-img" src="menu-button.451c8347.svg" alt="Menu"  onClick= {e => ToggleClick()}  /></div>
        { status ?  <div class="form-popup"  style={{display:'block',backgroundColor:'#4caf50',color:'white',position:'fixed',bottom:'100px',zIndex:'9',marginLeft:'100px',border:'1px solid rgb(76, 175, 80)',borderRadius:'5px'}}><p style={{}}><h4 style={{float:'left',margin:'5px'}}><ul style={{width:'200px'}}><li>{CategoryList}</li></ul></h4></p></div> :null } */}
            </div>
            
            {popUp && <ProductModifier setPopUp={setPopUp} product={product} />}
            {CustomizepopUp && <Customizable setCustomizePopUp={setCustomizePopUp} product={product} />}
        </>
    );

}

export default ProductItem;