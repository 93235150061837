import React,{lazy,Suspense} from 'react';
import ReactDOM from 'react-dom';
import Routes from './routes';
import * as serviceWorker from './serviceWorker';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";


import { HelmetProvider } from 'react-helmet-async';
//  import ProductsContextProvider from './contexts/ProductsContext';
// import CartContextProvider from './contexts/CartContext';
// import CategoryContextProvider from './contexts/CategoryContext';

//import Dashboard from "./pages/dashboard";
//import Store from './pages/store';
// import NotFound from './pages/NotFound';
// import Cart from "./pages/cart";
// import QRscanner from "./pages/QRscanner"


const ProductsContextProvider = lazy(() => import('./contexts/ProductsContext'));
const CartContextProvider = lazy(() => import('./contexts/CartContext'));
const CategoryContextProvider = lazy(() => import('./contexts/CategoryContext'));


const Dashboard = lazy(() => import('./pages/dashboard'));
const QRscanner = lazy(() => import('./pages/QRscanner'));
const Cart = lazy(() => import('./pages/cart'));
const NotFound = lazy(() => import('./pages/NotFound'));
const Store = lazy(() => import('./pages/store'));
const About = lazy(() => import('./pages/About'));
const PastOrders  = lazy(() => import('./pages/PastOrders'));
const printreceipt = lazy(() => import('./pages/cart/printreceipt'));
const Location = lazy(() => import('./components/gmap/MyGoogleMaps'));
const paymentpage = lazy(() => import('\./pages/cart/paymentpage'));

const contactus = lazy(() => import('./pages/contactus'));
const privacypolicy = lazy(() => import('./pages/privacypolicy'));
const termsconditions = lazy(() => import('./pages/termsconditions'));

const cancelrefund = lazy(() => import('./pages/cancelrefund'));


const buynow = lazy(() => import('./pages/buynow'));
const payresponce = lazy(() => import('\./pages/cart/payresponce'));





ReactDOM.render(
  <Router>
    <Suspense fallback={<div>Loading...</div>}>
  <Switch>

  <HelmetProvider>
 
  <ProductsContextProvider>
    <CartContextProvider>
    <CategoryContextProvider> 
    <Route exact path="/" component={Dashboard}   />
          <Route exact path="/Store" component={Store}/>
          <Route path="/cart" component={Cart} />
          <Route path="/QRscanner" component={QRscanner} />
          <Route path="/About" component={About} />
          <Route path="/PastOrders" component={PastOrders} />
          <Route path="/buynow" component={buynow} />
          <Route path="/Location" component={Location} />
          <Route path="/contactus" component={contactus} />
          <Route path="/privacypolicy" component={privacypolicy} />
          <Route path="/termsconditions" component={termsconditions} />
          <Route path="/cancelrefund" component={cancelrefund} />
          {/* <Route path="*" component={NotFound} /> */}
      </CategoryContextProvider>
    </CartContextProvider>
  </ProductsContextProvider>
  
  <Route path="/payresponce" component={payresponce} />  
  <Route path="/paymentpage" component={paymentpage} />
  <Route path="/printreceipt" component={printreceipt} />
   </HelmetProvider>
  </Switch>
  </Suspense>
   </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
