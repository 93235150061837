
import React, { useState, useContext } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import { Link } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import CartContext from "../../contexts/CartContext"
import Login from '../../pages/cart/Login';
import Outlettype from '../../pages/dashboard/Outlettype';
import { useHistory } from "react-router-dom";


let outletName = "";
let logo = "logo.jpg"
let outletAddress = ""
let Login_Name = "";
let Login_Mobile = "";
const useStyles1 = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
}));

export const Logininfo = (LoginName, LoginMobile) => {
   
  Login_Name = LoginName;
  Login_Mobile = LoginMobile;
  //alert(Login_Name);
}

export const changeOutletName = (name, logoPath, coutAddress) => {
  outletName = name;
  logo = logoPath;
  outletAddress = coutAddress;
}

export default function SwipeableTemporaryDrawer() {
   
  // const [Logininfo, setlogininfo] = useState(false);
  const [LoginInfo, setLoginInfo] = React.useState(false)
  const [popUp, setPopUpLogin] = useState(false)
  const [OutletpopUp, setPopUpOutlet] = useState(false)
  const classes = useStyles();
  let Logout_status = localStorage.getItem("loginstatus");

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  let history = useHistory();
const clearLocalStoargeofLoginInfo = () => 
{
 

window.localStorage.removeItem("Mobile");
window.localStorage.removeItem("Name");
window.localStorage.removeItem("currentUser");
window.localStorage.removeItem("loginstatus");
window.localStorage.removeItem("Billtype");
window.localStorage.removeItem("OutLetName");
history.push("/")

}


  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    // alert(popUp),
    <div
      style={{ textAlign: "center" }}
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div >
        <Link to='/' style={{ textDecoration: 'none' }}><img src={"Logo/" + logo} alt="Menu" /></Link>

        <Divider /> </div>
      <span
        className="card-title"
        style={{ width: "15%", fontSize: "14px", fontWeight: "600" }}
      >
        {/* <hr style={{  border:' 1px solid #b7b4b4 !important',padding:'0px',margin:'0px;'}} /> */}
        {/* {localStorage.getItem("OutLetName")} */}
        {outletName}


      </span>
      <br />
      <span
        className="card-title"
        style={{ width: "15%", fontSize: "14px", fontWeight: "600" }}
      >   {outletAddress}</span>
      <br />
      <span>
        {localStorage.getItem("Name")}
        {/* {Login_Name} */}
      </span>
      <br />
      <span>
        {localStorage.getItem("Mobile")}
        {/* {Login_Mobile} */}
      </span>
      <Divider />


      {localStorage.getItem("OutLetName") !== null ? ( 
    // <Link to='/store' style={{ textDecoration: 'none' }}>
<ListItem button key='Menu' onClick={()=>setPopUpOutlet(true)}>
          <ListItemIcon> <InboxIcon /> </ListItemIcon>
          Menu
        </ListItem>
        // </Link>
       ) : ( null
      )}
        <Divider />
      <List>
      <Link to='/QRscanner' style={{ textDecoration: 'none' }}>
        <ListItem button key='QR Scanner' >
          <ListItemIcon> <InboxIcon /> </ListItemIcon>
          QR Scanner
          {/* <ListItemText primary='QR Scanner' /> */}
        </ListItem>
        </Link>
        <Divider />




{Logout_status !== null ? ( 
    <Link to='/PastOrders' style={{ textDecoration: 'none' }}>
<ListItem button key='Past Orders'>
          <ListItemIcon> <InboxIcon /> </ListItemIcon>
        Past Orders
        </ListItem></Link>
       ) : ( null
      )}


<Link to="/buynow" style={{ textDecoration: 'none' }}>
<ListItem button key='buynow'>
  <ListItemIcon> <i class="fa fa-address-card"></i> </ListItemIcon>
  Buy Now  
</ListItem>
</Link>

    <Link to='/About' style={{ textDecoration: 'none' }}>
        <ListItem button key='About Us'>
          <ListItemIcon> <InboxIcon /> </ListItemIcon>
          About Us
        </ListItem>
     </Link>


<Link to="/contactus" style={{ textDecoration: 'none' }}>
<ListItem button key='contactus'>
  <ListItemIcon> <i class="fa fa-address-card"></i> </ListItemIcon>
  Contact Us
</ListItem>
</Link>

<Link to="/privacypolicy" style={{ textDecoration: 'none' }}>
<ListItem button key='privacypolicy'>
  <ListItemIcon> <i class="fa fa-address-card"></i> </ListItemIcon>
  Privacy Policy
</ListItem>
</Link>

<Link to="/termsconditions" style={{ textDecoration: 'none' }}>
<ListItem button key='termsconditions'>
  <ListItemIcon> <i class="fa fa-address-card"></i> </ListItemIcon>
  Terms & Conditions
</ListItem>
</Link> 

<Link to="/cancelrefund" style={{ textDecoration: 'none' }}>
<ListItem button key='cancelrefund'>
  <ListItemIcon> <i class="fa fa-address-card"></i> </ListItemIcon>
  Cancellations and Refunds
</ListItem>
</Link> 


        <Divider />

        <Link to="/" onClick={()=>localStorage.clear()} style={{ textDecoration: 'none' }}>

        <ListItem button key='About Us'>
          <ListItemIcon> <InboxIcon /> </ListItemIcon>
        Clear All
        </ListItem>
        
        </Link>
        
        {Logout_status !== null ? ( <Link to="/Location" style={{ textDecoration: 'none' }}> <ListItem button key='Track Order' >
          <ListItemIcon> <InboxIcon /> </ListItemIcon>
          <ListItemText primary='Track Order'  />
        </ListItem></Link>
       ) : null}
        {Logout_status !== null ? ( <ListItem button key='Log Out'  onClick={()=>clearLocalStoargeofLoginInfo()}>
          <ListItemIcon> <InboxIcon /> </ListItemIcon>
          <ListItemText primary='Log Out'  />
        </ListItem>
       ) : ( <ListItem button key='Login' onClick={()=>setPopUpLogin(true)} >
        <ListItemIcon> <InboxIcon /> </ListItemIcon>
        <ListItemText primary='Login'   />
      </ListItem>
      )}

      </List>
      <Divider />
    </div>
  );

  return (
    <div>
      {popUp && <Login setPopUpLogin={setPopUpLogin} />}
      {OutletpopUp && <Outlettype setPopUpOutlet={setPopUpOutlet} />}
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <div className={classes.root}>

            <AppBar position="static" >
              <Toolbar variant="dense">
                <IconButton onClick={toggleDrawer(anchor, true)} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                  <MenuIcon />&nbsp;&nbsp;&nbsp;

                </IconButton>
                <span className="outletname" style={{ textAlign: 'left', fontSize: '24px', width: '50%' }} >
                  {localStorage.getItem("OutLetName")}
                  &nbsp;&nbsp;

                </span>
                <span style={{ fontSize: '18px', width: '50%',textAlign:'right' }}> 
                  {/* {localStorage.getItem("Name")} */}
                  &nbsp;&nbsp;
                  {localStorage.getItem("Mobile")}</span>
                <section className={classes.rightToolbar}>
                  {/* <Typography variant="h6" className={classes.title}>
                  BINIX POS
  </Typography> */}
                  {/* <Button color="inherit">QR Scanner</Button> */}
                </section>
              </Toolbar>
            </AppBar>
          </div>









          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}
