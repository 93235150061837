import React, { createContext, useState, useEffect } from 'react';
import * as api from './api.js';
import { dummyProducts } from '../services/dummy';
import { useGlobalSpinnerActionsContext } from './GlobalSpinnerContext'

export const ProductsContext = createContext()

const ProductsContextProvider = ({ children }) => {

    //const [products] = useState(dummyProducts);
    const setGlobalSpinner = useGlobalSpinnerActionsContext()
    const [products, setPosts] = useState([]);

    useEffect(() => {
        loadPosts();

        async function loadPosts() {

            try {
                let qrcode = localStorage.getItem("QrOutLetId");
                let array = qrcode.split("##");
                let qrcodeid = array[0];
                let tableno = array[1];
                setGlobalSpinner(true);
                // /api/Product/MenuList?QROutletID=Q10001&TableName=1
                let data = "api/Product/MenuList?QROutletID=" + qrcodeid + "&TableName=" + tableno + "";
                api.AxiosApi(data)
                    .then((response) => {
                         
                        const posts = response.data;
                        setPosts(posts);
                        setGlobalSpinner(false);
                        // alert("MenuItems");
                    })
                    .catch(error => {
                        console.log(error);
                        setPosts([]);
                    });
            }

            catch (ex) {

            }
        }
    }, [setGlobalSpinner]);
    return (
        <ProductsContext.Provider value={{ products }} >
            { children}
        </ProductsContext.Provider>
    );
}
export default ProductsContextProvider;