import React, { useContext, useState, useEffect } from "react";
import Modal from "react-modal";
// import "../../assets/app.css";
import OtpInput from 'react-otp-input';
import { CartContext } from '../../contexts/CartContext';
import * as api from '../../contexts/api';

const Login = (props) => {
     
    const { loginUserInfo } = useContext(CartContext);

    const [userInfo, setUserInfo] = React.useState({
        guestName: '',
        mobileNo: '',
        otpValue: '',
        loginFlag: false
    });

    const [otpValue, setotpValue] = useState('');
    const handleChange = (otpValue) => {
        setotpValue(otpValue);
    }

    // useEffect(() => {});
    const useFormInput = initialValue => {
        const [value, setValue] = useState(initialValue);

        const handleChange = e => {
            setValue(e.target.value);
        }
        return {
            value,
            onChange: handleChange
        }
    }
    
    const { setPopUpLogin } = props;
    const [otpflag, setotpflag] = useState(false);
    const Name = useFormInput('');
    const Mobile = useFormInput('');
    const OTP = useFormInput('');

    let StoredOTP = localStorage.getItem("OTP");
    if (StoredOTP != null) {
        setPopUpLogin(false);
    }
    else {
        setPopUpLogin(true);
    }

    function toggleModal() {
         
        setPopUpLogin(false);
    }
    function SendOTP() {
         
        localStorage.setItem("Mobile", Mobile.value);
        localStorage.setItem("Name", Name.value);
        //alert(otpflag);
        setotpflag(true);
        //setPopUpLogin(false); 

        setUserInfo({ guestName: Name.value, mobileNo: Mobile.value, otpValue: '12345', loginFlag: false });
        loginUserInfo(userInfo);


    }

    const handleLogin = async (e) => {
         
        e.preventDefault();

        try {
            let GuestName = Name;
            let GuestNameMobile = Mobile;
            let response = await sendOTP(GuestName, GuestNameMobile);

            setotpflag(true);
            if (!response.user) return;
            props.history.push('/store');
        } catch (error) {
            console.log(error);
        }
    };
    async function sendOTP(GuestName, GuestNameMobile) {
         
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            // body: JSON.stringify(loginPayload),
        };

        try {

            let data;
            await getOTP();
            async function getOTP() {
                 
                try {

                    var qrcodeid = localStorage.getItem("cartId");
                    var tableno = "0";
                    let url = 'http://mobapi.binix.online/api/Product/LoginWithMobile?QrOutLetId=Q10002&MobileNo=7727050713&GuestName=Binix'
                    var data = "api/Product/LoginWithMobile?QROutletID=" + qrcodeid + "&MobileNo=" + GuestNameMobile.value + "&GuestName=" + GuestName.value + "";
                    api.AxiosApi(data)
                        .then((response) => {
                             

                            data = response.data.OtpDetail;
                            if (data) {
                                localStorage.setItem('currentUser', JSON.stringify(data[0]));
                                localStorage.setItem("Mobile", Mobile.value);
                                localStorage.setItem("Name", Name.value);
                                setotpflag(true);
                                setUserInfo({ guestName: Name.value, mobileNo: Mobile.value, otpValue: '12345', loginFlag: false });
                                loginUserInfo(userInfo);
                                return data[0];
                            }
                            //setGlobalSpinner(false);
                        })
                        .catch(error => {
                            console.log(error);

                            return;
                        });
                }

                catch (ex) {

                }

            }





        } catch (error) {

            console.log(error);
        }
    }
    function MatchOTP() {
         

        let userDetails = localStorage.getItem('currentUser');
        userDetails = JSON.parse(userDetails);
        let otp_status = userDetails.OTP;
        if (otpValue == otp_status) {
        localStorage.setItem("loginstatus", "success");
        //   statusOTP(dispatch,  true );
           
           // alert("Match")
        }
        else {
           // statusOTP(dispatch,  false );
          // 
          //  alert("D'nt Match")
        }
        //setUserInfo({ guestName: Name.value, mobileNo: Mobile.value, otpValue: '00000', loginFlag: true });
        //alert(userInfo.loginFlag);
        //loginUserInfo(userInfo);
        //localStorage.setItem("OTP", OTP.value);
        setPopUpLogin(false);



        //localStorage.setItem("OTP", OTP.value);
        setPopUpLogin(false);
        setUserInfo({ guestName: Name.value, mobileNo: Mobile.value, otpValue: '12345', loginFlag: false });
        loginUserInfo(userInfo);
    }

    return (
        <div>
        <Modal
            isOpen={setPopUpLogin}
            onRequestClose={toggleModal}
            contentLabel="My dialog"
            className="LogInPopUp"
            overlayClassName="myoverlay"
        >
            <div>
                {/* <h1>Select Item</h1> */}
                <div className="col-xs-12 col-md-12 card-container" style={{ textAlign: 'left', fontSize: '14px', paddingTop: '15px', paddingLeft: '10px' }}>
                    <h4>Log In</h4>
                    <div className="col-xs-12 col-md-12 card-container" style={{ textAlign: 'left', fontSize: '14px', paddingTop: '10px', paddingLeft: '10px' }}>

                        <div style={{ display: otpflag != false ? "none" : "block" }}>
                            <div className="col-xs-12 col-md-12 ">
                                <input type="text" placeholder="Name (Optional)" {...Name} autoComplete="none" style={{ width: '90%', marginLeft: '28px', marginBottom: '10px' }}></input>
                                <br />
                                +91 <input type="tel" maxLength="10" placeholder="Mobile Number"  {...Mobile} autoComplete="none" autoFocus="true" style={{ width: '90%' }}></input>
                            </div>
                            <br />
                            <div className="col-xs-12 col-md-12" style={{ textAlign: 'center' }}>
                                <button
                                    // onClick={() => handleLogin()}
                                    onClick={handleLogin} 
                                    // disabled={loading}
                                    style={{ width: '90%', marginLeft: '10px' }}>
                                         {/* {loading && (
                                        <i
                                            className="fa fa-refresh fa-spin"
                                            style={{ marginRight: "5px" }}
                                        />
                                    )}
                                    {loading && <span>Sending OTP.....</span>}
                                    {!loading && <span>Send OTP</span>}
                                     */}Send OTP
                                    
                                    </button>
                            </div>
                        </div>
                    </div>

                    <div style={{ display: otpflag != false ? "block" : "none" }}>
                        <div className="col-xs-12 col-md-12 " id="otpalign" >
                            {/* <input type="text" placeholder="Enter OTP" {...OTP} autoFocus="true" autoComplete="none" style={{ width: '90%', marginLeft: '28px', marginBottom: '10px' }}></input> */}
                            <OtpInput
                                isInputNum={true}
                                value={otpValue}
                                onChange={handleChange}
                                numInputs={4}
                                separator={<span>-</span>}
                                inputStyle={{ width: '50px' }}
                                shouldAutoFocus={true}
                               
                               
                            />
                        </div>
                        <br />
                        <div className="col-xs-12 col-md-12" style={{ textAlign: 'center' }}>
                            <button id="otploginbtn"
                                onClick={() => MatchOTP()}
                                style={{ width: '90%', marginLeft: '10px' }}>Verify OTP</button>
                        </div>
                    </div>
                </div>


            </div>
        </Modal>
    </div>
 );
};

export default Login;
