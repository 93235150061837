import React, { createContext, useReducer } from "react";
import { CartReducer, sumItems } from "./CartReducer";
import {Logininfo} from "../components/shared/swipeabledrawer"
export const CartContext = createContext();


// initialState = { cartItems: [], ...sumItems([]), checkout: false };

const CartContextProvider = ({ children }) => {      
       
  let qrcodeid = "1";
  try {
    qrcodeid = localStorage.getItem("cartId");
  } catch (ex) {}
        
  let storage;
  let initialState;

  storage = localStorage.getItem("cart" + qrcodeid + "")
    ? JSON.parse(localStorage.getItem("cart" + qrcodeid + ""))
    : [];
  qrcodeid = localStorage.getItem("cartId");
  initialState = { cartItems: storage, ...sumItems(storage), checkout: false };
    let [state, dispatch] = useReducer(CartReducer, initialState);
        
    const [userInfo, setUserInfo] = React.useState({
      guestName: '',
      mobileNo: '',
      otpValue: '',
      loginFlag: false
  });

  const increase = (payload) => {
     
    dispatch({ type: "INCREASE", payload });
  };
  const customize = (payload, data) => {
     debugger;
    dispatch({ type: "CUSTOMIZE", payload });
  };

  const decrease = (payload) => {
    dispatch({ type: "DECREASE", payload });
  };

  const addProduct = (payload) => {
    dispatch({ type: "ADD_ITEM", payload });
  };

 
  const removeProduct = (payload) => {
    dispatch({ type: "REMOVE_ITEM", payload });
  };

  const clearCart = () => {
    dispatch({ type: "CLEAR" });
  };

  const handleCheckout = () => {
    console.log("CHECKOUT", state);
    dispatch({ type: "CHECKOUT" });
  };
  const ADDInstructions = (payload, data) => {
     
    dispatch({ type: "ADD_Instructions", payload,data });
  };
  const loginUserInfo = (userInfo) => {
     
    setUserInfo({ guestName: userInfo.guestName, mobileNo: userInfo.mobileNo, otpValue:userInfo.otpValue, loginFlag: userInfo.loginFlag });
    //alert(userInfo.guestName +'  '+userInfo.mobileNo +' '+ userInfo.otpValue +' '+ userInfo.loginFlag);
    Logininfo(userInfo.guestName,userInfo.mobileNo)
  };


  const contextValues = {
    removeProduct,
    addProduct,
    increase,
    decrease,
    clearCart,
    handleCheckout,
    ...state,
    loginUserInfo,
    ADDInstructions,
    customize
  };

  return (
    <CartContext.Provider value={contextValues}>
      {children}
    </CartContext.Provider>
  );
};

export default CartContextProvider;
