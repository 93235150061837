import React, { useContext, useState, useEffect } from "react";
import Modal from "react-modal";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import { useHistory } from "react-router-dom";



const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
}));
const Outlettype = (props) => {
     
    let history = useHistory();
    const classes = useStyles();
    const { setPopUpOutlet } = props;

    const [Diningstate, setDiningstate] = React.useState(true);
    const [TakeAwaystate, setTakeAwaystate] = React.useState(true);
    const [Deliverystate, setDeliverystate] = React.useState(true);




    function toggleModal() {
         
        setPopUpOutlet(false);
    }
   const  LinkStorge = (dataaction) => {
         
      
        if (dataaction == "KOT") {
            localStorage.setItem("Billtype", "KOT");
            history.push('/Store?KOT');
        }
        if (dataaction == "BILL") {
            localStorage.setItem("Billtype", "BILL");
            history.push('/Store?BILL');
        }
        setPopUpOutlet(false);
    }


    return (
        <div>
            <Modal
                isOpen={setPopUpOutlet}
                onRequestClose={toggleModal}
                contentLabel="My dialog"
                className="outlettypepopup"
                overlayClassName="myoverlay"
            >

<>
     
        <Grid container spacing={1}>
         
            <>
              {Diningstate === true ? (
                <Grid item xs={4} sm={4} className="alignitemcenter">
                  <ButtonBase className={classes.image} onClick={() => LinkStorge("KOT")} >
                    <img src="img/Dining.png" style={{maxHeight:'75px',maxWidth:'75px',bottom:'45%',position:'fixed'}}/>
                    <div style={{bottom:'30%',position:'fixed',fontStyle:'normal',fontSize:'20px',fontWeight:'bold',color:'#ce0d1c'}}>Dining</div>
                  </ButtonBase>
                </Grid>
              ) : null}
              {TakeAwaystate === true ? (
                <Grid item xs={4} sm={4} className="alignitemcenter">
                  <ButtonBase className={classes.image} onClick={() => LinkStorge("BILL")}>
                    <img src="img/takeaway.png" style={{maxHeight:'75px',maxWidth:'75px',bottom:'45%',position:'fixed'}}/>
                    <div style={{bottom:'30%',position:'fixed',fontStyle:'normal',fontSize:'20px',fontWeight:'bold',color:'#ce0d1c'}}>Take Away</div>
                  </ButtonBase>
                </Grid>
              ) : null}
              {Deliverystate === true ? (
                <Grid item xs={4} sm={4} className="alignitemcenter">
                  <ButtonBase className={classes.image} onClick={() => LinkStorge("BILL")}>
                    <img src="img/delivery.png" style={{maxHeight:'75px',maxWidth:'75px',bottom:'45%',position:'fixed'}}/>
                    <div style={{bottom:'30%',position:'fixed',fontStyle:'normal',fontSize:'20px',fontWeight:'bold',color:'#ce0d1c'}}>Delivery</div>
                  </ButtonBase>
                </Grid>
              ) : null}
            </>
         
        </Grid>
        
    </>



             </Modal>
        </div>
    );
};

export default Outlettype;
