import React, { useContext, useState, useEffect } from "react";
import {
  PlusCircleIcon,
  MinusCircleIcon,
  TrashIcon,
} from "../../components/icons";
import Modal from "react-modal";
import "../../assets/app.css";
import { CartContext } from "../../contexts/CartContext";

const SpecialInstructions = (props) => {
      
  const { ADDInstructions } = useContext(CartContext);
  const { setPopUp } = props;

  const [inputValue, setInputValue] = useState ('');

  function toggleModal() {
    setPopUp(false);
  }
  function setMessage(value) {
     
    let data= props;
    data.product.instructions= value;

    // ADDInstructions("",value);
    setInputValue(value);
    
  }
  
  return (
    <div>
      <Modal
        isOpen={setPopUp}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        className="SpecialInstructionspopup"
        overlayClassName="myoverlay"
      >
        <div>
          {/* <h1>Select Item</h1> */}
          <div className="col-xs-12 col-md-12 card-container" style={{textAlign:'center'}}>
           
          <div className="col-xs-12 col-md-12 card-container" style={{  textAlign: 'left', fontSize: '14px', paddingTop: '5px' }}>
          <textarea placeholder="Special instructions...?"
           style={{width: "100%",borderRadius: "5px",height: "150px"}}
          //  onChange={e => setMessage(e.target.value)}
           value={props.product.instructions}
           onChange={e => ADDInstructions(props.product,e.target.value)}
           ></textarea>
           </div>

          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SpecialInstructions;
