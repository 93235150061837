import React, { useContext } from "react";
import Layout from "../../components/Layout";

import ProductsContextProvider from '../../contexts/ProductsContext';
import CategoryContextProvider from '../../contexts/CategoryContext';
import CartContextProvider from '../../contexts/CartContext';

import { CartContext } from "../../contexts/CartContext";
import CartProducts from "./CartProducts";
import { CartReducer,sumItems } from "../../contexts/CartReducer";
import { formatNumber } from "../../helpers/utils";
import { Link } from "react-router-dom";
import Location from "../../components/gmap/MyGoogleMaps";


const Cart = () => {
   
  const { taxamount,total, cartItems, itemCount, clearCart, checkout, handleCheckout } = useContext(CartContext);

  



//   var Item= cartItems;
//  
//   let data=  sumItems(cartItems); 
  return (
    <ProductsContextProvider>
    <CartContextProvider>
    <CategoryContextProvider>
    <Layout title="Cart" description="This is the Cart page"   >
      <div>
        <div className="text-center mt-5 tyu" >
          {/* <h3 style={{marginTop:'75px'}} >Your Order Details</h3> */}
          <p id="padd" style={{marginTop:'75px'}}>Your Order Details</p>
        </div>

        <div className="row no-gutters justify-content-center"   >
          <div className="col-sm-12 p-3 t"  >
            {cartItems.length >= 0 ? (
              <CartProducts />
            ) : (
              <div className="p-3 text-center text-muted">
                Your cart is empty
              </div>
            )}

            {checkout && (
              <div className="p-3 text-center text-success">
                <p>Checkout successfull</p>
                <Link to="/" className="btn btn-outline-success btn-sm">
                  Order More
                </Link>
              </div>
            )}
          </div>

          <div className="cleardix"></div>

        </div>
      </div>

      
    </Layout>
    </CategoryContextProvider>
     </CartContextProvider>
   </ProductsContextProvider>
  );
};

export default Cart;
