import axios from 'axios';
export function getAllMenuItems() {
    
  //  return axios.get('http://localhost:3000/sample2.json');
  // return axios.get('http://192.168.1.10:81/sample2.json');
  return axios.get('https://admincc.binix.online/api/User/MenuListNew');



// var result ;
// return axios.get('http://localhost:5000/api/User/MenuList')
//         .then(response => { 
//             
//           result =  response.data 
//         });
//       return  result


}
export function getAllCategory() {
  return axios.get('https://admincc.binix.online/api/User/CategoryList');
}
export function AxiosApi(data) {

    // return axios.get('http://localhost:5000/'+data);
    return axios.get('https://admincc.binix.online/'+data);
  }
export  function AxiosApiDataPost() {
   
    return 'https://admincc.binix.online/';
  }
 
  export default function AxiosApiDataPost123() {
   
    return 'https://admincc.binix.online/';
  }
 