import React, { useContext, useEffect, useState } from 'react';
import { CartContext } from '../../contexts/CartContext';
import axios from 'axios';
import CartItem from './CartItem';
import styles from './CartProducts.module.scss';
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import Login from '../cart/Login';
import AxiosApiDataPost from "../../contexts/api"

import * as api from '../../contexts/api.js';
import { useHistory } from "react-router-dom";

const CartProducts = () => {
  let Logout_status = localStorage.getItem("loginstatus");
  let Logintype = localStorage.getItem("Billtype");
  const [loginstatus, setloginstatus] = useState(false)


  let history = useHistory();
  const [popUp, setPopUpLogin] = useState(false)
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),

    },
  }));
  const classes = useStyles();

  const { taxamount, total, itemCount, cartItems, clearCart, checkout, handleCheckout } = useContext(CartContext);

  const CartDataPost = () => {
     
    let loginstatus = localStorage.getItem("loginstatus");
    let MobileNo = localStorage.getItem("Mobile")

    let actiontype = localStorage.getItem("Billtype");
    let GuestName = localStorage.getItem("Name");

    if (loginstatus !== "success") {
      // localStorage.setItem("loginstatus","success")
      setloginstatus(true);
      setPopUpLogin(true);
    }
    else {
      try {
        
        let qrcode = localStorage.getItem("QrOutLetId");
        var array = qrcode.split("##");
        var qrcodeid = array[0];
        var tableno = array[1];
        const user = {
          value: JSON.stringify(cartItems),
          QROutletID: qrcodeid,
          TableNo: tableno

        };
        const user_data={
          value:JSON.stringify(cartItems)
        }

        
      //var datata=JSON.stringify(cartItems)
      let MAINURL= api.AxiosApiDataPost();
      debugger;
       //var url = "https://admincc.binix.online/api/Product/Cart?QROutletID=" + qrcodeid + "&TableNo=" + tableno + "&action=" + actiontype + "&GuestName=" + GuestName + "&MobileNo=" + MobileNo + "&Address=125";
      var url = ""+MAINURL+"api/Product/Cart?QROutletID=" + qrcodeid + "&TableNo=" + tableno + "&action=" + actiontype + "&GuestName=" + GuestName + "&MobileNo=" + MobileNo + "&Address=125";
      axios.post(url ,
        { 'value': cartItems }
      )
      .then(response => {
        debugger

          console.log('Response:', response.data);

          let qrcode = localStorage.getItem("cartId");
            window.localStorage.removeItem("QrOutLetId");
            window.localStorage.removeItem("cartId");
            window.localStorage.removeItem("cart" + qrcode);

            let BillNo = response.data[0].BillNo
            if (BillNo != "0") {
               let QrOutLetId = response.data[0].QROutletID
                history.push({
                pathname: '/paymentpage',
                search: '?BillNo='+BillNo,
                state: { BillNo: BillNo,QrOutLetId:QrOutLetId }
              })
              // history.push('/paymentpage?QrOutLetId=' + QrOutLetId + '&BillNo=' + BillNo + '');
              //history.push('/printreceipt?QrOutLetId=' + QrOutLetId + '&BillNo=' + BillNo + '');
            }
            else {
              let BillNo = response.data[0].KotNo
              let QrOutLetId = response.data[0].QROutletID
              history.push('/PastOrders?MobileNo=' + MobileNo + '&QrOutLetId=' + QrOutLetId + '');
              let qrcode = localStorage.getItem("cartId");
             //  window.localStorage.removeItem("QrOutLetId");
             // window.localStorage.removeItem("cartId");
              window.localStorage.removeItem("cart" + qrcode);
            }
          // Handle the response
      })
      .catch(error => {
        debugger

          console.error('Error:', error.response ? error.response.data : error.message);
          // Handle the error
      });





         // some chnage in code 
        // let url = AxiosApiDataPost;
          // url = url + "api/Product/Cart?QROutletID=" + qrcodeid + "&TableNo=" + tableno + "&action=BILL";
          // debugger;
          // var url = "http://localhost:5160/api/Product/Cart?QROutletID=" + qrcodeid + "&TableNo=" + tableno + "&action=" + actiontype + "&GuestName=" + GuestName + "&MobileNo=" + MobileNo + "&Address=125";
          // axios.post(url.trim(), { 'value': cartItems })
          // .then(response => {
          //   debugger;
          //   let qrcode = localStorage.getItem("cartId");
          //   window.localStorage.removeItem("QrOutLetId");
          //   window.localStorage.removeItem("cartId");
          //   window.localStorage.removeItem("cart" + qrcode);

          //   let BillNo = response.data[0].BillNo
          //   if (BillNo != "0") {
          //      let QrOutLetId = response.data[0].QROutletID
          //       history.push({
          //       pathname: '/paymentpage',
          //       search: '?BillNo='+BillNo,
          //       state: { BillNo: BillNo,QrOutLetId:QrOutLetId }
          //     })
          //     // history.push('/paymentpage?QrOutLetId=' + QrOutLetId + '&BillNo=' + BillNo + '');
          //     //history.push('/printreceipt?QrOutLetId=' + QrOutLetId + '&BillNo=' + BillNo + '');
          //   }
          //   else {
          //     let BillNo = response.data[0].KotNo
          //     let QrOutLetId = response.data[0].QROutletID
          //     history.push('/PastOrders?MobileNo=' + MobileNo + '&QrOutLetId=' + QrOutLetId + '');
          //     let qrcode = localStorage.getItem("cartId");
          //    //  window.localStorage.removeItem("QrOutLetId");
          //    // window.localStorage.removeItem("cartId");
          //     window.localStorage.removeItem("cart" + qrcode);
          //   }
          // })
      }
      catch (ex) {
        debugger
          alert(ex.message);
      }
    }
  }

  return (
    <>
      <div className={styles.p__container} style={{ border: '1px solid rgb(239 239 239)' }}>
        <div className="carditm card-body border-0" style={{ padding: '5px', paddingRight: '0px' }}>

          {
            cartItems.map(product => <CartItem key={product.id} product={product} />)

          }

        </div>
      </div>


      {cartItems.length >= 0 && (
        <>

          <div className="col-sm-12 p-12">
            <textarea
              placeholder="Special instructions...?"
              style={{ width: "100%", borderRadius: "5px", height: "80px" }}
            ></textarea>
          </div>



          <Grid container spacing={1}>
            <Grid container item xs={12} spacing={1}>
              <Grid item xs={6} sm={6} >
                Item Total :{" "}
              </Grid>
              <Grid item xs={6} sm={6} style={{ textAlign: 'right' }}>
                {itemCount}
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={1}>
              <Grid item xs={6} sm={6} >
                Taxes and Charges :{" "}
              </Grid>
              <Grid item xs={6} sm={6} style={{ textAlign: 'right' }}>
                ₹ {taxamount}
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={1}>
              <Grid item xs={6} sm={6} >
                TO PAY :
              </Grid>
              <Grid item xs={6} sm={6} style={{ textAlign: 'right' }}>
                ₹ {(total)}
              </Grid>
            </Grid>
          </Grid>
        </>
      )}

      <div className="col-sm-12" id="checkout">

        <Link to="/Store" style={{
          color: "black",
          fontSize: "20px",

          fontSize: "16px",
          paddingTop: "1px",
        }}>
          <i class="fa fa-arrow-circle-left" style={{ fontSize: '30px', color: '#d9534f', paddingTop: "7px", float: 'left' }}></i>
          <span
            style={{
              color: "black",
              fontSize: "20px",
              float: "left",
              fontSize: "16px",
              paddingTop: "12px",
              paddingLeft: "10px",
            }}
          >
            <i class="fa fa-shopping-cart"></i>
            &nbsp;  {itemCount}
          </span>

          <h3
            className="col-sm-3 txt-right"
            style={{
              paddingTop: "15px",
              paddingLeft: "10px",
              float: "left",
              margin: "0px",
              fontSize: "16px",
            }}
          >
            ₹ {total}
          </h3>
        </Link>

        {/* <Link to="/Location"  > */}





        {Logintype === "KOT" ?
          (
            <button
              type="button"
              className="btn mb-2 Projectbackground-colorColor"
              //onClick={()=>setPopUpLogin(true)} 
              onClick={() => CartDataPost()}
              style={{ width: "50%", float: "right" }} >
              {Logout_status === "success" ? ("PLACE ORDER") : ("Login & ORDER")}

            </button>
          )
          :
          (
            <button
              type="button"
              className="btn mb-2 Projectbackground-colorColor"
              //onClick={()=>setPopUpLogin(true)} 
              onClick={() => CartDataPost()}
              style={{ width: "50%", float: "right" }} >
              {Logout_status === "success" ? ("PROCEED") : ("Login & PROCEED")}

            </button>
          )
        }


        {/* </Link> */}
      </div>
      {popUp && <Login setPopUpLogin={setPopUpLogin} />}
    </>

  );
}

export default CartProducts;