import React, { createContext, useState, useEffect} from 'react';
import { dummyCategory } from '../services/dummycategory';
import * as api from './api.js';


export const CategoryContext = createContext()


const CategoryContextProvider = ({children}) => {

//    const [category] = useState(dummyCategory);

const [category, setPosts] = useState([]);

useEffect(() => {

    loadPosts();

    async function loadPosts() {
        try{
        let qrcode=localStorage.getItem("QrOutLetId");
        var array = qrcode.split("##");
        var qrcodeid= array[0];
        var tableno= array[1];

        var data="api/Product/CategoryList?QROutletID="+qrcodeid+"&TableName="+tableno+"";

        api.AxiosApi(data)
            .then((response) => {
            const posts = response.data;                                                                                                                                                                                                                            
            setPosts(posts);
            // alert("Category");
        })
        .catch(error => {
            console.log(error);
            setPosts([]);
        });
    }
    
    catch (ex){
        
    }
    }

   
}, []);


    return ( 
        <CategoryContext.Provider value={{category}} >
            { children }
        </CategoryContext.Provider>
     );
}
 
export default CategoryContextProvider;