import React, { useContext,useState } from "react";
import {
  PlusCircleIcon,
  MinusCircleIcon,
  TrashIcon,
} from "../../components/icons";
import { CartContext } from "../../contexts/CartContext";
import SpecialInstructions from '../cart/SpecialInstructions';
import { formatNumber } from "../../helpers/utils";



     
const CartItem = ({ product }) => {
  const [popUp, setPopUp] = useState(false)
  const { increase, decrease, removeProduct } = useContext(CartContext);

  return (
    <>
    
    <div className="">
      {/* <div className="col-sm-2 p-2">
                <img
                alt={product.name}
                style={{margin: "0 auto", maxHeight: "50px"}} 
                src={product.photo} className="img-fluid d-block"/>
            </div> */}
      <div className="col-sm-8 e8" style={{float:'left',width:'85%'}} onClick={()=>setPopUp(true)} >
      {/* <img class="menubtn-img" src="img/veg.20069ac1.svg" alt="Menu" style={{float:'left',paddingTop:'5px'}} /> */}
      {product.menutype === 1 ? (
                                <img
                                    className="menubtn-img"
                                    src="img/veg.20069ac1.svg"
                                    alt="Menu" style={{float:'left',paddingTop:'5px'}}/>) 
                                :  null} 
                                {product.menutype  === 2  ? (
                                <img
                                    className="menubtn-img"
                                    src="img/nonveg.71128d53.svg"
                                    alt="Menu" style={{float:'left',paddingTop:'5px'}} />) 
                                :  null} 
                                {product.menutype  === 3  ? (
                                <img
                                    className="menubtn-img"
                                    src="img/egg_icon.png"
                                    alt="Menu" style={{float:'left',paddingTop:'5px'}}/>) 
                                :  null} 
                                &nbsp;
        <h5 className="mb-1" style={{float:'left',paddingLeft:'5px'}}>{product.name}</h5>  &nbsp;   
        <p className="mb-1"  style={{paddingLeft:'15px'}}>₹{product.price}
&nbsp;&nbsp;
        {product.instructions  !== ""  ? (
        <span style={{fontWeight:'bold'}}> {product.instructions} </span>)
      :
    (
     <span style={{color:'#c4c4c4'}}>  [
        Instructions...]
        </span>) }  </p>
       
      </div>
      <div className="col-sm-3 e3"  style={{float:'right'}}>
          <div style={{float:'left'}}>
            {product.quantity > 1 && (
              <button
                onClick={() => decrease(product)}
                className="btn btn-danger btn-sm mb-1"
              >
                <MinusCircleIcon width={"20px"} />
              </button>
            )}

            {product.quantity === 1 && (
              <button
                onClick={() => removeProduct(product)}
                className="btn btn-danger btn-sm mb-1"
              >
                <TrashIcon width={"20px"} />
              </button>
            )}
            </div>

            <div style={{float:'left',border: '1px solid #d2d1d1',width: '30px',textAlign:'center',height:'33px'}}>
         
         <p className="mb-0">{product.quantity}</p>
       
       </div>
       <div style={{float:'left'}}>
          <button onClick={() => increase(product)} style={{padding:'6px',borderTopLeftRadius:'0px',borderBottomLeftRadius:'0px',paddingRight:'10px',paddingLeft:'10px'}}>
            <PlusCircleIcon width={"20px"} />
          </button>
        </div>
      </div>
     
    </div>
  <hr className="my-4"/>
  {popUp && <SpecialInstructions setPopUp={setPopUp}  product={product}  />}
  </>
 );
};

export default CartItem;
