import React, { useState } from "react";
import { Fab, TextareaAutosize } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { Link } from "react-router-dom";
import QrScan from "react-qr-reader";
import Layout from "../components/Layout";
import Modal from "react-modal";
import { useHistory } from "react-router-dom";
import { changeOutletName } from "../components/shared/swipeabledrawer";
//const Fab;
//const TextareaAutosize;

const closeNav = () => {
   
  document.getElementById("mySidenav").style.width = "0";
};
function toggleModal() {}

function QRscanner() {
  let history = useHistory();
  const [qrscan, setQrscan] = useState("No result");
  const [OutLet, setOutLet] = React.useState("");
  const [Address, setAddress] = React.useState("");
  const [OutLetLogo, setOutLetLogo] = React.useState(null);

  const handleScan = (data) => {
    if (data) {
      setQrscan(data);
      console.log(data); 

      localStorage.setItem("OutLetName", "Samode");
      localStorage.setItem("QrOutLetId", "Q10001##1");
      localStorage.setItem("cartId", "Q10001");
      localStorage.setItem("Billtype", "KOT");

      changeOutletName("Samode", "C-SCHEME.png","C-SCHEME, Jaipur, Raj.");
      setAddress("C-SCHEME, Jaipur, Raj.")
      setOutLetLogo("C-SCHEME.png");
      history.push('/Store?KOT');

    }
  };
  const handleError = (err) => {
    console.error(err);
  };

  return (
    <div>
      <Modal
        isOpen={true}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        className="qrscannermodel"
        overlayClassName="myoverlay"
      >
        <center>
          {/* <i class="fa fa-times" style={{fontSize:'30px'}}></i> */}
          <div>
            <QrScan delay={300} onError={handleError} onScan={handleScan} />
          </div>
        </center>
      </Modal>
      <div class="col-sm-12 p-12" id="checkout">
      <Link to="/" id="btncance"><div class="col-sm-12 p-12" style={{width:'50%',float:'left',backgroundColor:'rgb(233 233 233)'}}>
        <button type="button" class="btn" > Cancel</button>
        </div></Link>

        <div class="col-sm-12 p-12" style={{width:'50%',float:'right',backgroundColor:'rgb(76, 175, 80)'}}>
        <button type="button" class="btn btn-primary mb-2" disabled="true" style={{width:'100%',textAlign:'center'}} >Submit</button>
        </div>
      </div>
    </div>
  );
}

export default QRscanner;
