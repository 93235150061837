
import React, { useContext, useState, useEffect } from "react";
import {
    PlusCircleIcon,
    MinusCircleIcon,
    TrashIcon,
} from "../../components/icons";
import { CartContext } from "../../contexts/CartContext";
import Modal from "react-modal";
import "../../assets/app.css";
import * as api from '../../contexts/api';
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing(3),
    },
}));

const Customizable = (props) => {
     
    const classes = useStyles();

    // const handleChange = (event) => {
    //     
    //    //event.target.checked;
    // };

    // function that takes boolean as param to conditionally display popup
    const { addProduct, cartItems, increase, decrease, removeProduct,customize } = useContext(CartContext);
    const [CustomizeData, setCustomizeData] = useState([]);
    const [CustomizeRate, setCustomizeRate] = useState([]);
    const { setCustomizePopUp } = props;
    const product = props.product;

    const [CustomizeSize, setCustomizeSize] = useState([]);
    const [CustomizeExtra, setCustomizeExtra] = useState([]);

    let selectedsize = [];
    let selectedextra = [];
    useEffect(() => {
         
        loadAddOnList();
    }, []);

    const IncrementItem = (data) => {
        debugger;
      
        if (isInCartQty(data) == 0) {
            addProduct(product);
            customize(product,1);
           // addCustomizeproduct(product);
        }
        else {
            increase(product)
            customize(product);
           // customizeincrease(product)
        }
        setCustomizePopUp(false)

    };
    const isInCartQty = product => {

        const cartData = cartItems.find(item => item.id === product.id);
        if (cartData) {
            if (cartData.quantity == 0) { removeProduct(product); }
            return cartData.quantity;
        }
        else { return 0; }

    }
    async function loadAddOnList() {
         
        let qrcode = localStorage.getItem("QrOutLetId");
        var array = qrcode.split("##");
        var qrcodeid = array[0];
        var MCode = product.id;
        // setGlobalSpinner(true);
        // /api/Product/MenuList?QROutletID=Q10001&TableName=1
        let data = "api/Product/AddOnList?QROutletID=" + qrcodeid + "&MCode=" + MCode + "";
        // let data = "AllOutLet"
        api.AxiosApi(data)
            .then((response) => {
                 
                const posts = response.data;
                setCustomizeSize(product.price);
                setCustomizeRate(product.price);
                setCustomizeData(posts);

            })
            .catch(error => {
                console.log(error);
                setCustomizeData([]);
            });
    }
    function toggleModal() {
        setCustomizePopUp(false);
    }
    function AddonAdded(e, event) {
         debugger;
        let status = event.target.checked;
        if (status === true) {
            if (e.multipleFlag === true) {
                if (CustomizeExtra.length == 0) {
                    setCustomizeExtra(parseInt(e.addOnRate));
                }
                else {
                    setCustomizeExtra(parseInt(e.addOnRate) + parseInt(CustomizeExtra));
                }
                if (CustomizeRate > CustomizeSize) { setCustomizeRate(e.addOnRate + CustomizeRate) }
                else { setCustomizeRate(e.addOnRate + CustomizeSize) }
            }

            else {

                setCustomizeSize(e.addOnRate);
                if (CustomizeRate > CustomizeSize) { setCustomizeRate(e.addOnRate + CustomizeExtra) }
                else { setCustomizeRate(e.addOnRate + CustomizeExtra) }

                //  let extras =  (CustomizeExtra - CustomizeSize)
                //  if(extras>0)
                //  {
                //     setCustomizeRate(e.addOnRate)
                //  }
                //  else
                //  {
                //     setCustomizeRate(e.addOnRate)
                //  }
            }
        }
        else {
            if (e.multipleFlag === false) {
                setCustomizeRate(e.addOnRate - CustomizeRate)
            }

            else {
                setCustomizeRate(CustomizeRate - e.addOnRate)
            }
        }

    }


    return (
        <div>
            <Modal
                isOpen={setCustomizePopUp}
                onRequestClose={toggleModal}
                contentLabel="My dialog"
                className="cutomizePopup"
                overlayClassName="myoverlay"
            >
                <div>
                    {/* <h1>Select Item</h1> */}
                    <div className="col-xs-12 col-md-12 card-container" style={{ textAlign: 'center' }}>

                        <div
                            className="col-xs-12 col-md-12 card-container" style={{ width: '60%', float: 'left', textAlign: 'left' }}>
                            <span
                                className="card-title"
                                style={{ fontSize: "14px", fontWeight: "600" }}
                            >
                                {product.menutype === 1 ? (
                                    <img
                                        className="menubtn-img"
                                        src="img/veg.20069ac1.svg"
                                        alt="Menu" />)
                                    : null}
                                {product.menutype === 2 ? (
                                    <img
                                        className="menubtn-img"
                                        src="img/nonveg.71128d53.svg"
                                        alt="Menu" />)
                                    : null}
                                {product.menutype === 3 ? (
                                    <img
                                        className="menubtn-img"
                                        src="img/egg_icon.png"
                                        alt="Menu" />)
                                    : null}
                                &nbsp;{" "}

                                &nbsp; {product.name}
                            </span>
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                            <span className="card-price">₹{product.price}</span>
                            {/* <span className="card-price">{formatNumber(product.price)}</span> */}
                        </div>
                        {localStorage.getItem(product.id) === null ? (
                            <div className="col-xs-12 col-md-12 card-container" style={{ width: '100%', float: 'left', textAlign: 'left' }}>

                                <hr style={{ marginTop: '10px', marginBottom: '10px' }} />
                                {CustomizeData.map((adddata) => (
                                    adddata.multipleFlag === false ? (
                                        <div className="custom-control custom-radio custom-control-inline" style={{ width: "100%" }}>
                                            <input type="radio" className="custom-control-input" id={adddata.addOnCode} name="example1"
                                                defaultChecked={adddata.selected}
                                                onClick={(e) => AddonAdded(adddata, e)} />
                                            <label className="custom-control-label" for={adddata.addOnCode} style={{ width: "100%" }}>
                                                <span style={{ float: 'left' }}>{adddata.addOnName}</span>
                                                <span style={{ float: 'right' }}> ₹  {adddata.addOnRate}</span>
                                            </label>  <br />
                                        </div>
                                    ) :
                                        null
                                )
                                )}
                                <hr style={{ marginTop: '10px', marginBottom: '10px' }} />
                                {CustomizeData.map((adddata) => (
                                    adddata.multipleFlag === true ? (

                                        <div className="custom-control custom-radio custom-control-inline" style={{ width: "100%" }}>
                                            {/* <input type="checkbox" className="custom-control-input" id={adddata.addOnCode} name="example1"
                                                defaultChecked={adddata.selected}
                                                onClick={(e) => AddonAdded(adddata)} />
                                            <label className="custom-control-label" for={adddata.addOnCode} style={{ width: "100%" }}>
                                                <span style={{ float: 'left' }}>{adddata.addOnName}</span>
                                                <span style={{ float: 'right' }}> ₹  {adddata.addOnRate}</span>
                                            </label>  */}
                                            <FormControlLabel
                                                control={<Checkbox defaultChecked={adddata.selected}
                                                    // onChange={handleChange} 
                                                    onChange={(e) => AddonAdded(adddata, e)}
                                                    name={adddata.addOnCode} />}
                                                label={adddata.addOnName}
                                            />
                                            <br />
                                        </div>



                                    ) :
                                        null
                                )
                                )}
                            </div>
                        )
                            :
                            (
                                <div className="col-xs-12 col-md-12 card-container" style={{ width: '100%', float: 'left', textAlign: 'left' }}>
   <br />  <br /><button style={{float:'left'}} >Add New</button>
   <button style={{float:'right'}}>Repeat Last</button>
                                </div>
                            )
                        }
                        <br />  <br />
                        <button
                            id={product.id + "e"}
                            onClick={(e) => IncrementItem(product)}
                            // onClick={()=>setPopUp(true)}
                            style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px', padding: '5px 10px 7px 10px', width: '100%', bottom: '0%', left: '50%', transform: 'translate(-50%, -0%)', position: 'fixed' }}>
                            Add to Cart +  ₹  <span>{CustomizeRate}</span>
                        </button>

                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default Customizable;
