import React, { useContext, useState } from "react";
import { CartContext } from "../../contexts/CartContext";
import ProductItem from "./ProductItem";
import { ProductsContext } from "../../contexts/ProductsContext";
import { PContext } from "../../contexts/ProductsContext";

import { CategoryContext } from "../../contexts/CategoryContext";
import styles from "./ProductsGrid.module.scss";
import "../../assets/PopUp.css";
import "../../assets/app.css";
import { useHistory } from "react-router-dom";
import Modal from "react-modal";
import { render } from "@testing-library/react";
import { Checkbox } from "react-bootstrap";
import Switch from "react-switch";

const ProductsGrid = () => {
   
  


  let history = useHistory();
  const { cartItems, total, itemCount } = useContext(CartContext);
  const { category } = useContext(CategoryContext);
  let { products } = useContext(ProductsContext);
 
  const [isOpen, setIsOpen] = useState(false);
  const [selCatId, setCatId] = useState(0);
  const [isChecked, setIsChecked] = useState(true);
  const [filteredProducts, setProductFilter] = useState(products);

  let categoryName = ""; // initialize status
  let eventKey = 0; // initialize status
  const [isOpened, setOpenclose] = useState(true);

  const handleChange = (checked) => {
    {
      //  this.setState({ checked });
    }
  };

  const ToggleOpenClose = () => {
    {
      setOpenclose(!isOpened);
    }
  };

  const ToggleClick = () => {
    {
      setIsOpen(!isOpen);
    }
  };
  const handleClick = (e, cat) => {
     

    //let btn = e.target;
    // btn.classList.add('active');

    setCatId(cat.category.cid);
    //alert(cat.category.cid)

    const yourVariable = cat.category.catName;
    var N = document.getElementById(yourVariable);
    if (N === null) {
      return;
    }
    N.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });

    // now you have access to your variable through the const declaration
  };
  function toggleModal() {
    setIsOpen(!isOpen);
  }

  function handleFilteration(event, srch) {
      
    let toSearch = "";
    if (srch.length > 0) {
      {
         
        if (!isChecked) {
           
          setIsChecked(!isChecked);
        //  toSearch = "1";
       // toSearch = srch;
          toSearch = "".toUpperCase();
        } else {
           
          setIsChecked(!isChecked);
         // toSearch = ("" + srch).toUpperCase();
          setProductFilter(
          
            products.filter((products) =>
               products.menutype === parseInt(srch)
             //  products.menutype.includes(parseInt(srch))
              // products.name.toUpperCase().includes(srch)
            )
          );
        return;


        // toSearch = "2";
       // toSearch = srch;
        }
      }
    } else {
       
     // toSearch = srch;
      toSearch = ("" + event.target.value).toUpperCase();
    }
    setProductFilter(
      products.filter((products) =>
        products.name.toUpperCase().includes(toSearch)
       
    //   products.menutype === parseInt(toSearch)
      
      )
    );
    console.log(products);
  }

  function setCategory(catName) {
    // categoryName != catName ?(
    // alert("no"+categoryName))
    // :
    // (alert("yes"+categoryName))
    // alert(oldcategory);
    //setCat(catName);
    categoryName = catName;
  }

  const setCategory1 = (catName) => {
    // categoryName != catName ?(
    // alert("no"+categoryName))
    // :
    // (alert("yes"+categoryName))
    //alert(catName+' ' +categoryName);
    //setCat(catName);
    categoryName = catName;
  };
  //   const closeNav = () => {
  //      
  //     document.getElementById("mySidenav").style.width = "0";
  //   };
  render();
  {
    function ClearTextBox(e) {
      e.preventDefault();
      document.getElementById("txt_serach").value = "";
      setProductFilter([]);
    }

    let productToDisplay = [];
    if (filteredProducts.length > 0) {
       
      productToDisplay = filteredProducts;
    } else {
      productToDisplay = products;
    }
    return (
      <div className={styles.p__container}>
        <div className="row">
          <div className="col-sm-12" id="search">
            <div className="form-group ">
              <div className="form-group has-search">
                <span className="fa fa-search form-control-feedback"></span>
                <input
                  type="text"
                  name=""
                  autoComplete={false}
                  placeholder="Search product"
                  onChange={(e) => handleFilteration(e, "")}
                  className="form-control"
                  id="txt_serach"
                  style={{
                    boxShadow: "none",
                    marginBottom: "10px",
                    borderBottom: "1px solid #cacaca",
                    float: "left",
                    width: "75%",
                    background: "white",
                  }}
                />
                <button
                  class="btn btn-secondary"
                  onClick={ClearTextBox}
                  type="button"
                  style={{
                    float: "left",
                    width: "10%",
                    padding: "0px",
                    minHeight: "39px",
                    borderBottom: "1px solid rgb(202, 202, 202)",
                  }}
                >
                  <i
                    class="fa fa-trash"
                    style={{ color: "#ff7373", fontSize: "19px" }}
                  ></i>
                </button>
                <div style={{ width: "15%", float: "right" }}>
                  <Switch
                    checked={!isChecked}
                    onChange={(e) => handleFilteration(e, "2")}
                    onColor="#86d3ff"
                    onHandleColor="#2693e6"
                    handleDiameter={30}
                   // uncheckedIcon={false}
                    //checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.1)"
                    activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.2)"
                    height={8}
                    width={40}
                    uncheckedIcon={
                      <div
                        style={{
                          paddingTop: "10px",
                          alignItems: "right",
                          height: "100%",
                          fontSize: 15,
                          color: "Black",
                        }}
                      >
                        All
                      </div>
                    }
                    checkedIcon={
                      <div
                        style={{
                          paddingTop: "10px",
                          alignItems: "left",
                          height: "100%",
                          fontSize: 15,
                          color: "Green",
                        }}
                      >
                        Veg
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            className={styles.p__grid}
            id="Grid"
            style={{ padding: "3px", marginTop: "15px;" }}
          >
            {productToDisplay.map((product) => (
              <div>
                {product.catName != categoryName ? (
                  <>
                    <div
                      className={styles.scrollmargintop}
                      onClick={(e) => ToggleOpenClose()}
                      style={{
                        border: "1px solid #e4e2e2",
                        borderRadius: "5px",
                        textAlign: "left",
                        color: "rgb(136 134 134)",
                        background: "rgb(239 239 239)",
                        fontWeight: "bold",
                        textAlign: "left",
                        padding: "5px",
                        marginBottom: "5px",
                      }}
                      id={product.catName}
                    >
                      {product.catName}
                    </div>
                    <div style={{ display: "none" }}>
                      {(categoryName = product.catName)}
                    </div>
                  </>
                ) : (
                  <div></div>
                )}

                <ProductItem
                  key={product.id}
                  product={product}
                  categoryName={product.catName}
                />
              </div>
            ))}
          </div>
          {/* CAT MENU START */}

          <div className="col-sm-3" onClick={(e) => ToggleClick()}>
            <img
              className="menubtn-img"
              src="img/menu-button.451c8347.svg"
              alt="Menu"
              id="Menu"
            />

            {isOpen ? (
              <div>
                <Modal
                  isOpen={isOpen}
                  onRequestClose={toggleModal}
                  contentLabel="My dialog"
                  className="mymodal"
                  overlayClassName="myoverlay"
                >
                  <div style={{}}>
                    <h4
                      style={{
                        float: "left",
                        margin: "5px",
                        width: "100%",
                        margin: "0px",
                      }}
                    >
                      <ul type="reset">
                        {category.map((category) => (
                          <li
                            type="reset"
                            value={category.catName ? category.catName : ""}
                            style={{ width: "100%" }}
                          >
                            <button
                              type="button"
                              // className={isActive}
                              className={
                                category.cid != selCatId
                                  ? "ActiveClass"
                                  : "DeActiveClass"
                              }
                              style={{
                                textAlign: "left",
                                background: "white",
                                color: "black",
                                fontSize: "15px",
                                fontWeight: "bold",
                                padding: "5PX",
                                width:'90%'
                              }}
                              onClick={(e) => handleClick(e, { category })}
                              id={category.cid}
                            >
                              {category.catName}
                            </button>
                            <div style={{ float: "right", textAlign: "right", padding:"5px",width:'10%'}}
                             onClick={(e) => handleClick(e, { category })}
                            > {category.mCount}</div>
                          </li>
                        ))}
                      </ul>
                    </h4>
                  </div>
                </Modal>
              </div>
            ) : null}
          </div>
          {/* CAT MENU END */}

          {cartItems.length > 0 ? (
            <>
              <div className="col-lg-4 col-md-4 col-xs-4 fixed-bottom"></div>
              <div
                className="col-lg-5 col-md-5 col-xs-5 fixed-bottom"
                id="BottomMenuBar"
                style={{
                  display: "block",
                  backgroundColor: "#4caf50",
                  color: "white",
                  // position: "fixed",
                  bottom: "0",
                  zIndex: "9",
                }}
              >
                <div>
                  <div className="row">
                    <div
                      className="col-sm-12"
                      style={{
                        paddingRight: "0px",
                        maxHeight: "50px",
                        backgroundColor: "rgb(233, 233, 233)",
                      }}
                    >
                      <p style={{ paddingTop: "0px" }}>
                        <div className="form-group">
                          <div
                            style={{
                              width: "50%",
                              float: "left",
                              background: "rgb(233 233 233)",
                              color: "black",
                            }}
                          >
                            <h4 style={{ float: "left" }}>
                              <p
                                style={{
                                  float: "left",
                                  paddingTop: "10px",
                                  fontSize: "16px",
                                }}
                              >
                                <i
                                  class="fa fa-shopping-cart"
                                  style={{ fontSize: "20px" }}
                                ></i>{" "}
                                &nbsp;{itemCount}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </p>{" "}
                              <p style={{ float: "right", paddingTop: "10px" }}>
                                {" "}
                                &nbsp; ₹ {total}
                              </p>
                            </h4>
                          </div>
                          <div
                            style={{
                              width: "50%",
                              float: "right",
                              backgroundColor: "rgb(76 175 80)",
                            }}
                          >
                            <button
                              id="viewcart"
                              class="Projectbackground-colorColor"
                              style={{
                                width: "100%",
                                height: "50px",
                                fontSize: "16px",
                              }}
                              onClick={() => history.push("/cart")}
                            >
                              VIEW CART
                            </button>
                          </div>
                        </div>{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-xs-4 fixed-bottom"></div>
            </>
          ) : (
            <div></div>
          )}
          <div className={styles.p__footer}></div>
        </div>
      </div>
    );
  }
};

export default ProductsGrid;
