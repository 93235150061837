const Storage = (cartItems) => {
  
  let qrcodeid = "";
  let GetQrValue = "";
  try {
    qrcodeid = localStorage.getItem("cartId");
  } catch (ex) {}
if(qrcodeid != null)
{
  localStorage.setItem(
    "cart" + qrcodeid + "",
    JSON.stringify(cartItems.length > 0 ? cartItems : [])
  );
}
};


let taxinclusive = (Rate, Price, Qty) => {
   
  let gstamt = (Price * Qty) - ((Price * Qty) * (100 / (100 + Rate)))
  let netprice = (Price * Qty) - gstamt;
  return parseFloat(gstamt).toFixed(2);
}
let taxexclusive = (Rate, Price, Qty) => {
   
  let gstamt = ((Price * Qty) * Rate) / 100;
  let netprice = (Price * Qty) + gstamt;
  return parseFloat(gstamt).toFixed(2);
}


export const sumItems = (cartItems) => {
            
  Storage(cartItems);
  let itemCount = cartItems.reduce(
    (total, product) => total + product.quantity,
    0
  );
  let total = cartItems
    .reduce((total, product) => total + product.price * product.quantity, 0)
    .toFixed(2);
    let taxamount = 0;
    let Itemcart = cartItems;
    for (let item of Itemcart) {

      if (item.taxInclusive == true) {
        var TempTaxAmount = taxinclusive(item.taxRate, item.price, item.quantity);
        taxamount = parseFloat(taxamount + TempTaxAmount * 1).toFixed(2);
      } else {
        var TempTaxAmount = taxexclusive(item.taxRate, item.price, item.quantity);
        taxamount = parseFloat(taxamount + TempTaxAmount * 1).toFixed(2);
      }
    } 

  let cartid = localStorage.getItem("cartId");
  return { itemCount, total,taxamount };
};




export const CartReducer = (state, action) => {
           
  debugger;
  let qrcodeid = "1";
  try {
    qrcodeid = localStorage.getItem("cartId");
  } catch (ex) {}

  
  switch (action.type) {
    case "ADD_ITEM":
           
      if (!state.cartItems.find((item) => item.id === action.payload.id)) {
        state.cartItems.push({
          ...action.payload,
          quantity: 1,
        });
      }

      return {
        ...state,
        ...sumItems(state.cartItems),
        cartItems: [...state.cartItems],
      };
     
    case "REMOVE_ITEM":
      return {
        ...state,
        ...sumItems(
          state.cartItems.filter((item) => item.id !== action.payload.id)
        ),
        cartItems: [
          ...state.cartItems.filter((item) => item.id !== action.payload.id),
        ],
      };
    case "INCREASE":
      state.cartItems[
        state.cartItems.findIndex((item) => item.id === action.payload.id)
      ].quantity++;
      return {
        ...state,
        ...sumItems(state.cartItems),
        cartItems: [...state.cartItems],
      };
      case "CUSTOMIZE":
      state.cartItems[
        state.cartItems.findIndex((item) => item.id === action.payload.id)
      ].addonsize = "1";
      state.cartItems[
        state.cartItems.findIndex((item) => item.id === action.payload.id)
      ].addonextra = "1";
      return {
        ...state,
        ...sumItems(state.cartItems),
        cartItems: [...state.cartItems],
      };
      
    case "DECREASE":
      state.cartItems[
        state.cartItems.findIndex((item) => item.id === action.payload.id)
      ].quantity--;
      return {
        ...state,
        ...sumItems(state.cartItems),
        cartItems: [...state.cartItems],
      };
    case "CHECKOUT":
      return {
        cartItems: [],
        checkout: true,
        ...sumItems([]),
      };
    case "CLEAR":
      return {
        cartItems: [],
        ...sumItems([]),
      };
      return {
        ...state,
        ...sumItems(state.cartItems),
        cartItems: [...state.cartItems],
      };
      case "ADD_Instructions":   
       
      state.cartItems[
        state.cartItems.findIndex((item) => item.id === action.payload.id)].instructions=action.data;
      return {
        ...state,
        ...sumItems(state.cartItems),
        cartItems: [...state.cartItems],
      };
    default:
      return state;
  }
};
