import React, { useState, createContext, useContext } from 'react'

const GlobalSpinnerContext = createContext()
const GlobalSpinnerActionsContext = createContext()


/* eslint-disable */
const useContextFactory = (name, context) => {
  return () => {
  const ctx = useContext(context)
    if (ctx === undefined) {
      throw new Error(`use${name}Context must be used withing a ${name}ContextProvider.`)
    }
    return ctx
  }
}
/* eslint-enable */
 
export const useGlobalSpinnerContext = () => useContext(GlobalSpinnerContext)
export const useGlobalSpinnerActionsContext = () => useContext(GlobalSpinnerActionsContext)



// export const useGlobalSpinnerActionsContext = () => {
//   const context = useContext(GlobalSpinnerActionsContext)
//   if (context === undefined) {
//     throw new Error(`useGlobalSpinnerActionsContext must be called within GlobalSpinnerContextProvider`)
//   }
   
//   return context
// }

// export const useGlobalSpinnerContext = () => {
//   const context = useContext(GlobalSpinnerContext)
//   if (context === undefined) {
//     throw new Error(`useGlobalSpinnerContext must be called within GlobalSpinnerContextProvider`)
//   }
//   return context
// }

// export const useSpinnerContext = () => {
//   const context = useContext(GlobalSpinnerContext)
//   if (context === undefined) {
//     throw new Error(`useGlobalSpinnerActionsContext must be called within GlobalSpinnerContextProvider`)
//   }
   
//   return context
// }

//export const useGlobalSpinnerContext = useContextFactory('GlobalSpinnerContext', GlobalSpinnerContext)
//export const useGlobalSpinnerActionsContext = useContextFactory('GlobalSpinnerActionsContext', GlobalSpinnerActionsContext)


const GlobalSpinnerContextProvider = (props) => {
  const [isGlobalSpinnerOn, setGlobalSpinner] = useState(false)
  
  return (
    <GlobalSpinnerContext.Provider value={isGlobalSpinnerOn}>
      <GlobalSpinnerActionsContext.Provider value={setGlobalSpinner}>
        {props.children}
      </GlobalSpinnerActionsContext.Provider>
    </GlobalSpinnerContext.Provider>
  )
}

export default GlobalSpinnerContextProvider