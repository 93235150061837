import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Fab, TextareaAutosize } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { Link } from "react-router-dom";
import Layout from "../../components/Layout";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
//import OutletContext from '../../contexts/OutletContext';
import { CartContext } from "../../contexts/CartContext";
import { changeOutletName } from "../../components/shared/swipeabledrawer";

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import { useHistory } from "react-router-dom";
import * as api from '../../contexts/api.js';
let options = [];

const Dashboard = () => {
  let history = useHistory();
  const [OutLet, setOutLet] = React.useState("");
  const [Address, setAddress] = React.useState("");
  const [OutLetLogo, setOutLetLogo] = React.useState(null);
  // const [inputValue, setInputValue] = React.useState("");

  const [Diningstate, setDiningstate] = React.useState(false);
  const [TakeAwaystate, setTakeAwaystate] = React.useState(false);
  const [Deliverystate, setDeliverystate] = React.useState(false);

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      marginTop: 70,
      maxWidth: 500,
      minHeight: 550,
      height: '100%'
    },
  }));
  const classes = useStyles();
  useEffect(() => {
      debugger;
    var Url= api.AxiosApiDataPost()+"AllOutLet";
    debugger;
    axios.get(Url).then((response) => {
      debugger;  
      options = response.data;
      
      CallAfterSelectOutlet("a");
    });
  }, []);

  const CallAfterSelectOutlet = (SelectedOutletDetails) => {
    {
       
      //localStorage.clear();
      try {
        let tableno = 1;
        let QrOutLetId = SelectedOutletDetails.qrOutletID;
        let QrOutLetIdwithtable = SelectedOutletDetails.qrOutletID + "##" + tableno;
        //window.localStorage.removeItem("QrOutLetId");
        setOutLet(SelectedOutletDetails.outLetName);

        if (SelectedOutletDetails != "a") {
          localStorage.setItem("OutLetName", SelectedOutletDetails.outLetName);
          localStorage.setItem("QrOutLetId", QrOutLetIdwithtable);
          localStorage.setItem("cartId", QrOutLetId);
          changeOutletName(SelectedOutletDetails.outLetName, SelectedOutletDetails.outLetImg, SelectedOutletDetails.address);
          setAddress(SelectedOutletDetails.address)
          setOutLetLogo(SelectedOutletDetails.outLetImg)

        } else {

        }

      } catch (ed) { }
      setDiningstate(SelectedOutletDetails.dining)
      setTakeAwaystate(SelectedOutletDetails.takeaway)
      setDeliverystate(SelectedOutletDetails.delivery)
     
    }
  };
  const LinkStorge = (dataaction) => {
    if (dataaction == "KOT") {
      localStorage.setItem("Billtype", "KOT");
      history.push('/Store?KOT');
    }
    if (dataaction == "BILL") {
      localStorage.setItem("Billtype", "BILL");
      history.push('/Store?BILL');
    }
  }
  const closeNav = () => {
    document.getElementById("mySidenav").style.width = "0";
  };
  return (
    <>
      <Layout
        title="Store"
        description="This is the Store page"
        style={{ alignSelf: "center" }}
      >
        {/* <Paper className={classes.paper}> */}
        <Grid container spacing={1}>
          <Grid item xs={12}>

            <Link to="/QRscanner">
              <ButtonBase className={classes.image}>
                <img src="img/QRScanner.png" id="qrscannerbutton" />
              </ButtonBase>
            </Link>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              value={OutLet}
              blurOnSelect
              getOptionLabel={(option) => option.outLetName}
              onChange={(event, SelectedOutletDetails) => {
                CallAfterSelectOutlet(SelectedOutletDetails);
              }}
              // inputValue={inputValue}
              // onInputChange={(event, newInputValue) => {
              //   setInputValue(newInputValue);
              // }}
              id="controllable-states-demo"
              options={options}
              style={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  placeholder="Select Outlet"
                  {...params}
                  hiddenLabel="true"
                  // label="Select Outlet"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <ButtonBase className={classes.image}>
              {OutLetLogo !== null ? (<img
                className="menubtn-img"
                src={"Logo/" + OutLetLogo}
                alt="Logo"
                id="outletlogo"
              />) : (<img
                className="menubtn-img"
                src="img/logo.jpg"
                alt="Binix"
                id="binixlogo"
              />)}
            </ButtonBase>
          </Grid>
          <Grid item xs={12}>
            <div id="AutoCompleteValue">
              <div style={{ fontWeight: "bold", fontSize: "20px" }}>{` ${OutLet !== undefined ? `${OutLet}` : ""
                }`}</div>
              <div>{`${Address}`}</div>
              {/* <div>{`inputValue: '${inputValue}'`}</div> */}
            </div>
          </Grid>
          {/* <Grid item xs={6} sm={6}> */}
          {OutLet !== undefined ? (
            <>
              {Diningstate === true ? (
                <Grid item xs={4} sm={4} className="alignitemcenter">
                  <ButtonBase className={classes.image} onClick={() => LinkStorge("KOT")} >
                    <img src="img/Dining.png" id="hidebuttonDinning" />
                    <div id="hidebuttonDinningText">Dining</div>
                  </ButtonBase>
                </Grid>
              ) : null}
              {TakeAwaystate === true ? (
                <Grid item xs={4} sm={4} className="alignitemcenter">
                  <ButtonBase className={classes.image} onClick={() => LinkStorge("BILL")}>
                    <img src="img/takeaway.png" id="hidebuttonPickup" />
                    <div id="hidebuttonPickupText">Take Away</div>
                  </ButtonBase>
                </Grid>
              ) : null}
              {Deliverystate === true ? (
                <Grid item xs={4} sm={4} className="alignitemcenter">
                  <ButtonBase className={classes.image} onClick={() => LinkStorge("BILL")}>
                    <img src="img/delivery.png" id="hidebuttonPickup" />
                    <div id="hidebuttonPickupText">Delivery</div>
                  </ButtonBase>
                </Grid>
              ) : null}
            </>) : null}
          {/* </Grid> */}
          {/* <Grid item xs={6} sm={6}> */}
          {/* {value !== undefined ? (<><Grid item xs={6} sm={6} className="alignitemcenter"><Link to="/Store"><div id="hidebuttonDinningText">Dining</div></Link></Grid><Grid item xs={6} sm={6} className="alignitemcenter"><Link to="/Store"><div id="hidebuttonPickupText">Take Away</div></Link></Grid></>) : null} */}
          {/* </Grid> */}
        </Grid>
        {/* </Paper> */}
      </Layout>
    </>
  );
};

export default Dashboard;
