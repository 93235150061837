import React from 'react';
import Layout from '../components/Layout';
import { GitHubIcon} from '../components/icons'

const About = () => {
    
    return ( 
        <Layout title="About" description="This is the About page" >
            <div className="text-center mt-5">
                <br/>
                <h1>About</h1>
                <p>  <strong>  BINIX Software Private Limited</strong>
               is well-established software Development Company, established in the year of 1998. We are specializing in providing custom solutions for small and large-scale businesses. We pay special attention to the ease of use and utilize the latest in technology.
                    
                    
                    
                    
                      by BINIX.</p>

                <a className="btn btn-primary" href="http://www.binix.in/">
                    <GitHubIcon width={"18px"}/> <span className="ml-2 mr-4">Visit Website</span></a>
            </div>
        </Layout>
     );
}
 
export default About;